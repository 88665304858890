import React, { FC } from 'react';
import { SanityClient } from 'lib/sanity';
import { REVALIDATION_TIME } from 'constants/values';
import {
  Capabilities,
  CaseStudyPages,
  Clients,
  Settings,
  Homepage,
  StudioStories,
} from 'lib/sanity/types';
import CaseStudyGridItem, {
  MouseoverImageDisplay,
} from 'atoms/CaseStudyGridItem';

import Meta from 'components/Meta';
import Link from 'next/link';
import SimpleFooter from 'components/SimpleFooter';

type Props = {
  settings: Settings;
  homepage: Homepage;
  caseStudies: CaseStudyPages;
  capabilities: Capabilities;
  clients: Clients;
  studioStories: StudioStories;
};

const Home: FC<Props> = ({
  settings,
  homepage,
  caseStudies,
  capabilities,
  clients,
  studioStories,
}) => {
  const { metaCard } = settings;
  // Split headline into lines (lines are separated by \n, defined in Sanity)
  const lines = homepage.headline.split('\n');
  return (
    <>
      <Meta seo={metaCard} />

      <section className="z-10 relative bg-black pb-20 px-6 rounded-b-3xl mb-[60vh] tablet:mb-[32vh]">
        <div className="content-max-width">
          {/* Header */}
          <div className="grid gap-6 tablet:grid-cols-2 mb-12">
            <div>
              <h3 id="clients" className="text-white py-6 text-small font-book">
                Hydraulics
              </h3>
            </div>
            <div>
              {homepage.clientDescription && (
                <p className="text-white py-6 text-xl font-book leading-[2.8rem] break-normal">
                  Hydraulics works with{' '}
                  <a
                    href="https://www.xxix.co"
                    target="_blank"
                    rel="noreferrer"
                    className="bg-gray-04 py-0 px-2 rounded-lg"
                  >
                    XXIX
                  </a>{' '}
                  and{' '}
                  <a
                    href="https://www.sanctuary.computer"
                    target="_blank"
                    rel="noreferrer"
                    className="bg-gray-04 py-0 px-2 rounded-lg inline-block"
                  >
                    Sanctuary Computer
                  </a>{' '}
                  to create digital products and web experiences of all scales.
                  Whether you&apos;ve got a napkin sketch or a successful
                  product at scale, we&apos;ll help you launch a simple,
                  intuitive experience.
                </p>
              )}
            </div>
          </div>

          {/* Content */}
          <div className="grid gap-6 tablet:grid-cols-2 laptop:grid-cols-3 desktop:grid-cols-4">
            {clients.map((c) => {
              return (
                <figure key={c._id} className="mb-6">
                  <h3 className="text-white text-small font-book">{c.name}</h3>
                  <p className="pb-6 text-gray-01 text-small font-book">
                    {c.blurb}
                  </p>
                </figure>
              );
            })}
          </div>
        </div>
      </section>

      <footer className="fixed bottom-0 left-0 right-0 z-0 w-full h-screen bg-white pb-10 px-6 flex items-end">
        <SimpleFooter settings={settings} />
      </footer>
    </>
  );
};

export const getStaticProps = async () => {
  const [
    settings,
    homepage,
    caseStudies,
    capabilities,
    clients,
    studioStories,
  ] = await Promise.all([
    SanityClient.fetchSettings(),
    SanityClient.fetchHomepage(),
    SanityClient.fetchCaseStudies(),
    SanityClient.fetchCapabilities(),
    SanityClient.fetchClients(),
    SanityClient.fetchStudioStories(),
  ]);

  if (!settings || !homepage || !caseStudies || !capabilities || !clients) {
    return {
      notFound: true,
    };
  }

  return {
    props: {
      settings,
      homepage,
      caseStudies,
      capabilities,
      clients,
      studioStories,
      globals: {
        settings,
      },
    },
    revalidate: REVALIDATION_TIME,
  };
};

export default Home;
